<template>
 <div v-if="dataLoading" id="loading" class="row" style="position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); z-index:100">
    <img src="@/assets/loading.gif" width="100" height="100">
</div> 
<nav class="col-md-4 offset-md-4 navbar navbar-light bg-light">
    <img class="logo" src="@/assets/logo-king-power_3x.png">
</nav>
 
    <div class="container">  
      
        <div class="col-md-6 offset-md-3">
           
            <h4 style="color:#3355a5">Customer consent form</h4>
            <div class="text-left mb-4">
                <label>Firstname</label>
                <input ref="txt_name" class="form-control" type="text" v-model="name" require autocomplete="off" readonly>
            </div>
            <div class="text-left mb-4">
                <label>Lastname</label>
                <input ref="txt_lastname" class="form-control" type="text" v-model="lastname" require  autocomplete="off" readonly>
            </div>
            <div class="text-left mb-4">
                <label>Nationality</label>
                <input class="form-control" type="text" v-model="nationality"  autocomplete="off" readonly>
            </div>
            <!--div class="text-left mb-4">
                <label>Country {{country}}</label>
                <input class="form-control" type="text" v-model="country"   autocomplete="off">
            </div-->
            <div class="text-left mb-4">
                <label>Passport</label>
                <input class="form-control" type="text" v-model="passport"  autocomplete="off" readonly>
            </div>
            <div class="text-left mb-4">
                <label>PersonalID</label>
                <input class="form-control" type="text" v-model="personalID"  autocomplete="off" readonly>
            </div>
             <div class="text-left mb-4">
                <label>Mobile</label>
                <input class="form-control" type="text" v-model="mobile" autocomplete="off" readonly>
            </div>
            <div class="text-left mb-4">
                <label>Email</label>
                <input class="form-control" type="text" v-model="email" autocomplete="off" readonly>
            </div>
            <div class="text-left mb-4" style="font-size:14px">
             <input style="width:20px;height:25px" type="checkbox" id="acept_privacy" v-model="acept_privacy" value="acept_privacy">&nbsp; I have read and acknowledge of King Power's
            <a href='https://www.kingpower.com/content/terms-and-condition?lang=en' target='_blank'>"Term & Condtions"</a> and <a href='https://www.kingpower.com/content/privacy-policy?lang=en'  target='_blank'>"Privacy Policy"</a>
            <br>
             <input style="width:20px;height:25px" type="checkbox" id="acept_promotion" v-model="acept_promotion" value="acept_promotion">
             &nbsp;I hereby agree and consent to receive <a href='https://www.kingpower.com/content/privacy-policy-marketing?lang=en' target='_blank'>advertisement, promotion and/or any marketing special offer</a> from King Power via the given contact.

            </div>
            <div class="mb-12" style="margin: 10px;">
            <button style="width: 200px;margin: auto;" class="btn btn-sm btn-primary btn-block" :disabled="!acept_privacy" @click="chkData">Save</button>
            </div>
        </div>  
    </div>
</template>
<script>
//import HelloWorld from "@/components/HelloWorld.vue"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import axios from "axios"

// var config = {
//     headers: {
//         "Content-Type":"multipart/form-data"
//     }
// };
//== production
var url_pdpa = "https://pdpa-api.kingpower.com";

//=== dev
//var url_pdpa = "/api";
//var url_pdpa = "https://pdpa-api-dev.kingpower.com";

export default {
    name:"consent",
    data(){
        return {
            dataLoading : false,
            uuid: "",
            name : "",
            lastname : "",
            nationality: "",
            country: "",
            passport: "",
            personalID: "",
            mobile:"",
            email:"",
            memberID:"",
            acept_promotion: false,
            acept_privacy:true,
            updateRes : null,
            chkRes: null,
            userInfo: null,
            acept_status_promotion : "",
            acrpt_status_privacy : "",
            btn_status : true,
            params : "",
            login_token : "",
            res_login: null
        }
    },
    mounted() {
        this.dataLoading = true;
        //console.log(this.$route.query);
        this.uuid = this.$route.query.uuid;
        //console.log(this.uuid);
        // if(this.acept_privacy == false){
        //     this.btn_status = true
        // }else{
        //     this.btn_status = false
        // }
        this.chkCustomerinfo();
    },
    methods: {
        chkData(e){
            document.activeElement.blur();
            e.preventDefault();
            // if(this.name == ""){
            //     Swal.fire( 
            //         'Oops...',
            //         'Please check name!',
            //         'warning'
            //         ).then(r => {
            //              if(r){
            //                  this.$refs.txt_name.focus()
            //              }
            //         })
                
            // } else if( this.lastname == ""){
            //      Swal.fire( 
            //             'Oops...',
            //             'Please check lastname !',
            //             'warning'
            //         ).then(r => {
            //              if(r){
            //                  this.$refs.txt_lastname.focus()
            //              }
            //         })
            //  }else{
            //     this.updateConsent();
            //     //this.chkConsent();
            //  }
             this.updateConsent();
        },
        updateConsent(){
            this.dataLoading = true;
            if(this.acept_promotion == true){ 
                this.acept_status_promotion = "Active"       
            }else{
                this.acept_status_promotion = "Inactive"
            }
            const json = {
                        "firstname": this.name,
                        "lastname": this.lastname,
                        "nationality": this.nationality,
                        "country": this.country,
                        "email": this.userInfo.email,
                        "passport": this.userInfo.passport,
                        "personalID": this.userInfo.personalID,
                        "mobile": this.userInfo.mobile,
                        "promotionConsent": this.acept_status_promotion,
                        "memberID":this.memberID,
                        "platform": this.userInfo.platform
            }
            axios.post(url_pdpa+"/updateConsent",json,{ headers:{"Authorization":"Bearer "+this.login_token }}).then(res => {
                    this.updateRes = res.data;
                    //console.log(res.status)
                    //console.log(this.updateRes)
                    if(res.status == 200){
                        this.dataLoading = false;
                         Swal.fire( 
                    'Thank you',
                    'data saved successfully',
                    'success'
                    ).then(r => {
                         if(r){
                            // this.$refs.txt_name.focus()
                            //console.log("Thank you")
                            this.$router.push({ name: 'Thankyou' })
                         }
                    })
                    }else{
                        this.dataLoading = false;
                         Swal.fire( 
                            'Oops...',
                            'Save data fail',
                            'error'
                        )
                    }        
                })
                .catch(error => {
                    this.dataLoading = false;
                    Swal.fire( 
                            error.response.status,
                            error.response.data.message,
                            'error'
                    )
            });
        },
        chkConsent(){      
            
/*             if(this.passport != ""){
                 this.params += "&passport="+this.passport
             }
             if(this.personalID != ""){
                this.params += "&personal_id="+this.personalID
             }
             if(this.email != ""){
                this.params += "&email="+this.email
             }
             if(this.mobile != ""){
                this.params += "&mobile="+this.mobile
             }
*/
             const json = {
                        "firstname": this.name,
                        "lastname": this.lastname,
                        "passport": this.userInfo.passport,
                        "personalID": this.userInfo.personalID,
                        "email": this.userInfo.email,
                        "mobile": this.userInfo.mobile,
                        "memberID": this.memberID
            }
             axios.post(url_pdpa+"/chkConsent",json,{headers:{"Authorization":"Bearer "+this.login_token}}).then(res => {
                    this.chkRes = res.data;
                    //console.log(this.chkRes.status)    
                    if(this.chkRes.status == "found"){
                        this.dataLoading = false;
                         this.acept_privacy = true;
                         if(this.chkRes.content.PromotionConsent == "Active"){
                             this.acept_promotion = true;
                         }
                        /* Swal.fire( 
                            'Thank you',
                            'You already have information in this section.',
                            'info'
                        ).then(r => {
                            if(r){
                                // this.$refs.txt_name.focus()
                                console.log("Thank you")
                            }
                        })*/
                    }else  if(this.chkRes.status == "fail"){
                        this.dataLoading = false;
                         Swal.fire( 
                            'Error',
                            this.chkRes.error_message,
                            'error'
                        ).then(r => {
                            if(r){
                                console.log("Thank you")
                            }
                        })
                    }else{
                        this.dataLoading = false;
                    }                             
            })
            .catch(error => {
                    this.dataLoading = false;
                    Swal.fire( 
                            error.response.statusText,
                            error.response.data.detail,
                            'error'
                    )
            });
        },
        chkCustomerinfo(){
             const json = {
                    "username": "pdpa",
                    "password": "posadmin"
                }    
             axios.post(url_pdpa+"/user/login",json).then(res => {
                         this.res_login = res.data
                         
                         if(res.status == 200){
                            if(this.res_login.access_token != ""){
                                this.login_token = this.res_login.access_token
                                axios.get(url_pdpa+"/getCustInfo/"+this.uuid,{headers:{"Authorization":"Bearer "+this.login_token}}).then(res => {
                                    this.userInfo = res.data;
                                    //console.log(this.userInfo)
                                    this.name = this.userInfo.firstname;
                                    this.lastname = this.userInfo.lastname; 
                                    this.nationality = this.userInfo.nationality;
                                    this.passport = this.userInfo.passport; 
                                    this.personalID = this.userInfo.personalID;   
                                    this.mobile = this.userInfo.mobile;
                                    this.email = this.userInfo.email;  
                                    this.memberID = this.userInfo.memberID;
                                    if(this.userInfo.passport != ""){
                                        //console.log(this.userInfo.passport.length)
                                        if(this.userInfo.passport.length > 5){
                                           this.passport = this.textmask(this.userInfo.passport,2);
                                       }
                                    }

                                    if(this.userInfo.personalID != ""){
                                        if(this.userInfo.personalID.length > 5){
                                           this.personalID = this.textmask(this.userInfo.personalID,4);
                                       }
                                    }

                                    if(this.userInfo.mobile != ""){
                                        if(this.userInfo.mobile.length > 5){
                                           this.mobile = this.textmask(this.userInfo.mobile,3);
                                       }
                                    }

                                    if(this.userInfo.email != ""){
                                        if(this.userInfo.email.length > 5){
                                           this.email = this.textmask(this.userInfo.email,4,'email');
                                       }
                                    }

                                   this.chkConsent();                     
                                })
                                .catch(error => {
                                    this.dataLoading = false;
                                    console.log(error.response)
                                    Swal.fire( 
                                            error.response.statusText,
                                            error.response.data.detail,
                                            'error'
                                     )
                                });
                            }
                         }
            })
            .catch(error => {
                this.dataLoading = false;
                Swal.fire( 
                        error.response.statusText,
                        error.response.data.detail,
                        'error'
                    )
            });
             
        },
        textmask(str,first,type=""){
            var nums = 0;
            var last = 0;
            var top = "";
            var body = "";
            var bottom="";
            var x="";
            var i=0;
            if(type == ""){
                 nums = str.length;
                 last = nums - first;
                 top = str.substring(0, first);
                 body = str.substring(first,last);
                 bottom = str.substring(last);
                // expected output: "zilla"
                 x = "";
                 i = 0;
                for(i=0;i< body.length;i++){
                x += "x"
                }
                //console.log(top+x+bottom);
                return top+x+bottom;
            }else if(type== 'email'){
                const words = str.split('@');
                //console.log(words);
                 nums = words[0].length;
                if(words[0].length > 4){
                     last = nums - first;
                     top = words[0].substring(0,last);
                     bottom = words[0].substring(last);
                     x = "";
                     i = 0;
                    for(i=0;i< bottom.length;i++){
                        x += "x"
                    }
                    return top+x+'@'+words[1];
                }else{
                    return str;
                }
            }
        }
         
    },
    
}
</script>
<style scope>
    .mb-4 { margin-bottom:3px !important;}
    label {font-weight: bold; font-size:14px}
    .logo{ width:90%; margin:10px}
    h4{ margin-top:20px;}
</style>