<template lang="">
    <nav class="col-md-4 offset-md-4 navbar navbar-light bg-light">
        <img class="logo" src="@/assets/logo-king-power_3x.png">
    </nav>
    <div class="container">   
        <div class="col-md-4 offset-md-4">
            <h4>Thank you for your information.</h4>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scope>
    h4{ color:#000000}
</style>