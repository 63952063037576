import { createWebHistory, createRouter } from "vue-router";
import Consent from "@/views/Consent.vue";
import Thankyou from "@/views/Thankyou.vue";




const routes = [
    {
       path: "/",
       name: "Consent",
       component: Consent
     },  
     {
      path: "/thankyou",
      name: "Thankyou",
      component: Thankyou
    }, 
];
   
const router = createRouter({
    history: createWebHistory(),
    routes,
  });
   
export default router;